import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/core";
import {
  BlurbWrapper,
  BlurbText,
  BlurbLink,
  WhiteLinkButton,
  GreenLinkButton,
  FullPageOrangeSection,
  WhiteSection,
  OrangeSection,
  IconWrapper,
  GreenBreak,
  TitleText,
} from "../components/styled-components";

import Hero from "../components/hero";
import MainLayout from "../components/main-layout";
import HexTransition from "../components/hex-transition";
import LargeIcon from "../components/large-icon";
import HorizontalTextCarousel from "../components/horizontal-text-carousel";
import ConsultingLinks from "../components/consulting-links";

const RootIndex = ({ data }) => {
  const heroOne = data.allContentfulHeroImage.nodes.find(
    (image) => image.slug === "top"
  );
  const heroThree = data.allContentfulHeroImage.nodes.find(
    (image) => image.slug === "bottom"
  );
  return (
    <>
      <MainLayout>
        <FullPageOrangeSection>
          <div
            css={css`
              width: 100%;
            `}
          >
            <Hero
              fluid={heroOne.heroImage.fluid}
              alt={heroOne.alt}
              type={"WtoO"}
              image={"header"}
            />
            <TitleText color="green">AIM FOR A CLEAN TOMORROW</TitleText>
          </div>
          <BlurbWrapper>
            <BlurbText color="#1a4428">
              BOW Renewables offers a targeted, customized approach to renewable
              energy solutions for your portfolio. Trust our expert energy
              consultants to show you how to seamlessly integrate solar power in
              a safe, environmentally responsible manner.{" "}
              <BlurbLink to="/about#contact" color="#215732" stripHash>
                Contact us
              </BlurbLink>{" "}
              to learn how simple it is to reap the benefits of today's clean
              energy economy for years to come.
            </BlurbText>
          </BlurbWrapper>
          <GreenLinkButton to="/about#us" stripHash>
            ABOUT US
          </GreenLinkButton>
        </FullPageOrangeSection>
        <WhiteSection>
          <HexTransition type={"OtoW"} text={"GO SOLAR"} />
          <br />
          <BlurbWrapper>
            <BlurbText color="#1a4428">
              Whether you’re a land owner, agricultural professional or
              commercial operation, you can optimize your property by converting
              your existing assets into a solar powerhouse. Maximize your bottom
              line with cost- efficient, clean, renewable energy.
            </BlurbText>
          </BlurbWrapper>
          <br />
          <br />
          <IconWrapper>
            <div
              data-sal="slide-left"
              css={css`
                width: 40%;
                min-width: 250px;
                max-width: 350px;
                display: flex;
                flex-direction: column;
                align-items: center;
              `}
            >
              <LargeIcon type="barn" />
              <br />
              <GreenLinkButton to="/gosolar#landowner" stripHash>
                AGRICULTURAL
              </GreenLinkButton>
            </div>
            <div
              data-sal="slide-right"
              css={css`
                width: 40%;
                min-width: 250px;
                max-width: 350px;
                display: flex;
                flex-direction: column;
                align-items: center;
              `}
            >
              <LargeIcon type="biz" />
              <br />
              <GreenLinkButton to="/gosolar#business" stripHash>
                COMMERCIAL
              </GreenLinkButton>
            </div>
          </IconWrapper>
          <br />
          <br />
          <GreenBreak>
            <HorizontalTextCarousel />
          </GreenBreak>
          <HexTransition type={"GtoW"} text={"TARGETED APPROACH"} />
          <br />
          <br />
          <BlurbWrapper>
            <BlurbText color="#1a4428">
              No two businesses are alike. We offer customized renewable energy
              solutions to fit your individual goals.
            </BlurbText>
          </BlurbWrapper>
          <br />
          <br />
          <ConsultingLinks />
          <br />
          <br />
        </WhiteSection>
        <GreenBreak>
          <BlurbWrapper>
            <h3
              css={css`
                color: white;
                text-align: center;
                @media (max-width: 960px) {
                  font-size: 1rem;
                }
              `}
            >
              The best projects are built by a strong team. Contact us to
              discuss partnerships in a joint development project.{" "}
            </h3>
            <br />
            <WhiteLinkButton to="/customsolutions">
              CUSTOM SOLUTIONS
            </WhiteLinkButton>
          </BlurbWrapper>
        </GreenBreak>
        <OrangeSection>
          <div
            css={css`
              width: 100%;
            `}
          >
            <Hero
              fluid={heroThree.heroImage.fluid}
              alt={heroThree.alt}
              type={"WtoO"}
            />
            <TitleText color="green">SUCCESS STORIES</TitleText>
          </div>
          <br />
          <BlurbWrapper>
            <BlurbText color="#1a4428">
              BOW Renewables is proud to have consulted on projects from coast
              to coast. When you work with BOW Renewables, we understand we are
              an extension of your existing team, and pride ourselves on working
              with honesty, integrity and full transparency.
            </BlurbText>
            <br />
            <BlurbText color="#1a4428">
              Our commitment to getting the job done right cannot be
              understated. Our only strategy is your success. We personally
              stand by every project from start to finish and stop at nothing to
              ensure your trust.
            </BlurbText>
          </BlurbWrapper>
          <br />
          <GreenLinkButton to="/projects">PROJECTS</GreenLinkButton>
        </OrangeSection>
      </MainLayout>
    </>
  );
};

export default RootIndex;

export const query = graphql`
  query HeroQuery {
    allContentfulHeroImage(filter: { tags: { in: "home" } }) {
      nodes {
        heroImage {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        heroAlt
        slug
      }
    }
  }
`;
