import React from "react";
import { css, keyframes } from "@emotion/core";

const slideShow = keyframes`
    0% {
      left: 25%
    }

    100% {
      left: -400%;
    }
  `;

const stats = [
  {
    header: "7,300",
    desc: "Acres Leased",
  },
  {
    header: "Over 30",
    desc: "Projects Managed to C.O.D.",
  },
  {
    header: "681",
    desc: "Megawatts Under Development",
  },
];

const HorizontalTextCarousel = () => {
  return (
    <div
      css={css`
        position: relative;
        width: 90vw;
        max-width: 600px;
        height: 100px;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
      `}
    >
      <div
        css={css`
          z-index: 100;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            90deg,
            rgba(33, 87, 50, 1) 0%,
            rgba(33, 87, 50, 0) 5%,
            rgba(33, 87, 50, 0) 50%,
            rgba(33, 87, 50, 0) 95%,
            rgba(33, 87, 50, 1) 100%
          );
        `}
      />
      <div
        css={css`
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          width: 400%;
          left: 0;
          top: 0;
          animation: ${slideShow} 20s linear infinite;
          transform: translate3d(0, 0, 0);
        `}
      >
        <div></div>
        {stats.map((stat, index) => (
          <div
            key={index}
            css={css`
              text-align: center;
            `}
          >
            <h1
              css={css`
                font-size: 3rem;
                color: white;
              `}
            >
              {stat.header}
            </h1>
            <h3
              css={css`
                font-size: 1.5rem;
                color: white;
              `}
            >
              {stat.desc}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalTextCarousel;
