import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

import LargeIcon from "./large-icon";
import {
  Arrow,
  GreenLinkButton,
  DescriptionWrapper,
} from "./styled-components";

const IconWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 25%;
  height: fit-content;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 50%;
    min-width: 250px;
  }
`;

const ConsultingLinks = () => {
  return (
    <div
      css={css`
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        @media (max-width: 1024px) {
          flex-direction: column;
        }
      `}
    >
      <IconWrapper>
        <LargeIcon type="acq" />
        <br />
        <DescriptionWrapper>
          <p>Own the land, harness the power.</p>
        </DescriptionWrapper>
        <GreenLinkButton to="/consulting#acquisition" stripHash>
          ACQUISITION
        </GreenLinkButton>
      </IconWrapper>
      <Arrow />
      <IconWrapper>
        <LargeIcon type="dev" />
        <br />
        <DescriptionWrapper>
          <p>Remove the “work” from legwork.</p>
        </DescriptionWrapper>
        <GreenLinkButton to="/consulting#development" stripHash>
          DEVELOPMENT
        </GreenLinkButton>
      </IconWrapper>
      <Arrow />
      <IconWrapper>
        <LargeIcon type="manage" />
        <br />
        <DescriptionWrapper>
          <p>Stay on target.</p>
        </DescriptionWrapper>
        <GreenLinkButton to="/consulting#management" stripHash>
          MANAGEMENT
        </GreenLinkButton>
      </IconWrapper>
    </div>
  );
};

export default ConsultingLinks;
